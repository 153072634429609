@tailwind utilities;
@tailwind base;

@layer base{
	h1, h2, h3, h4, h5, h6{
		font-weight: 700;
	}
	html{
		line-height: normal;
	}
	button{
		text-align: left;
	}
	img{
		max-width: none;
	}
	p{
		font-weight: 400;
	}
	.body{
		position: relative;
		z-index: 0;
	}
	.body::before{
		z-index: -1;
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 200vh;
		top: -100vh;
		background-image: radial-gradient(#ccccff30, transparent 60%);
		background-attachment: initial;
		background-position: center top;
		background-repeat: no-repeat;
	}
	.main{
		position: relative;
		display: flex;
		justify-content: center;
		gap: 40px;
		flex-direction: column;
		align-items: center;
		text-transform: uppercase;
	}
	.main::before{
		z-index: -1;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: url(assets/bg.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		animation: breath ease 5s infinite;
	}
	.profile-diagonal::before{
		z-index: 0;
		background-color: #6441a5;
		background-image: linear-gradient(135deg, #5a3a94 25%, transparent 25%), linear-gradient(225deg, #5a3a94 25%, transparent 25%), linear-gradient(45deg, #5a3a94 25%, transparent 25%), linear-gradient(315deg, #5a3a94 25%, #6441a5 25%);
		background-position: 40px 0, 40px 0, 0 0, 0 0;
		background-size: 80px 80px;
		background-repeat: repeat;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		clip-path: polygon(0% 0, 80% 0%, 40% 100%, 0 100%);
	}
	.clip-image{
		clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
	}
	@keyframes breath {
		0%{
			opacity: 0.6;
		}
		50%{
			opacity: 1;
		}
		100%{
			opacity: 0.6;
		}
	}
}

* {
    margin: 0;
    padding: 0;
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtE6F15M.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWvU6F15M.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtU6F15M.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuk6F15M.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWu06F15M.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWtk6F15M.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWt06F15M.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* hebrew */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
	unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* vietnamese */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Russo One';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOy8mJrrg.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Russo One';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOwcmJrrg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Russo One';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsHOz8mJ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Twemoji Country Flags';
    unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
    src: url('https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2') format('woff2');
}